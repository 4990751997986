<template>
  <span style="white-space: nowrap; font-size: larger">
    &radic;<span style="text-decoration: overline">&nbsp;{{ text }}&nbsp;</span>
  </span>
</template>

<script>
export default {
  props: ["text"],
};
</script>
