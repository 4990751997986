<template>
  <div class="container">
    <span class="description">b ve c sıfır olsa bile lütfen yazınız.</span>
    <div>
      <input
        class="input"
        id="equation-input"
        type="text"
        placeholder="ax^2 + bx + c"
        v-model="equation"
      />
      <button
        class="btn btn-submit"
        @click="
          if (equation.includes('x') && equation.includes('x^')) {
            calculate();
          }
        "
      >
        HESAPLA
      </button>
    </div>
    <div class="first-view">
      <span>x₁: {{ kök1_1 }}</span>
      <span>x₂: {{ kök2_1 }}</span>
    </div>
    <div class="second-view">
      <div class="box">
        <span class="title">x₁: </span>
        <div style="display: flex; flex-direction: column">
          <span
            >{{ -kök1_2.b == 0 ? null : -kök1_2.b }} +
            {{
              simplifySqrt(Math.abs(kök1_2.delta))[0] == 1
                ? null
                : simplifySqrt(Math.abs(kök1_2.delta))[0]
            }}
            <SquareRoot
              v-if="simplifySqrt(Math.abs(kök1_2.delta))[1] != 1"
              :text="simplifySqrt(Math.abs(kök1_2.delta))[1]"
            />{{ kök1_2.delta < 0 ? "i" : null }}
          </span>
          <hr />
          <span>{{ 2 * kök1_2.a }}</span>
        </div>
      </div>
      <div class="box">
        <span class="title">x₂: </span>
        <div style="display: flex; flex-direction: column">
          <span
            >{{ -kök2_2.b == 0 ? null : -kök2_2.b }} +
            {{
              simplifySqrt(Math.abs(kök2_2.delta))[0] == 1
                ? null
                : simplifySqrt(Math.abs(kök2_2.delta))[0]
            }}
            <SquareRoot
              v-if="simplifySqrt(Math.abs(kök2_2.delta))[1] != 1"
              :text="simplifySqrt(Math.abs(kök2_2.delta))[1]"
            />{{ kök2_2.delta < 0 ? "i" : null }}
          </span>
          <hr />
          <span>{{ 2 * kök2_2.a }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//SİLİMCİ OKTAY
import SquareRoot from "./components/SquareRoot.vue";
import { ref, reactive } from "vue";
const firstView = ref("none");
const secondView = ref("none");
const equation = ref("");
const kök1_1 = ref(0);
const kök2_1 = ref(0);
const kök1_2 = reactive({ a: 0, b: 0, c: 0, delta: 0 });
const kök2_2 = reactive({ a: 0, b: 0, c: 0, delta: 0 });

function replace(text, a, b) {
  let arr = text.split("");
  return arr.map((char) => (char == a ? b : char)).join("");
}
function isSquare(n) {
  return n >= 0 && Math.sqrt(n) % 1 === 0;
}
function simplifyNegativeSqrt(n) {
  let abs = Math.sqrt(-n);
  let factor = 1;
  for (let i = 2; i <= abs; i++) {
    while (abs % (i * i) === 0) {
      factor *= i;
      abs /= i * i;
    }
    if (abs % i === 0) {
      factor *= i;
      abs /= i;
    }
  }
  return [
    { re: 0, im: factor },
    { re: abs, im: 0 },
  ];
}
function simplifySqrt(n) {
  let outside_root = 1;
  let inside_root = n;
  let d = 2;
  while (d * d <= inside_root) {
    if (inside_root % (d * d) == 0) {
      inside_root = inside_root / (d * d);
      outside_root = outside_root * d;
    } else {
      d = d + 1;
    }
  }
  return [outside_root, inside_root];
}

function float2rat(x) {
  var tolerance = 1.0e-6;
  var max_iter = 100;
  var max_den = 1000;

  if (Math.abs(x - Math.round(x)) < tolerance) return "" + Math.round(x);

  var sgn = x >= 0 ? 1 : -1;
  x = Math.abs(x);
  var b = x;

  var h1 = 1;
  var h2 = 0;
  var k1 = 0;
  var k2 = 1;

  for (var i = 0; i < max_iter; i++) {
    var a = Math.floor(b);
    var tmp;

    tmp = h1;
    h1 = a * h1 + h2;
    h2 = tmp;

    tmp = k1;
    k1 = a * k1 + k2;
    k2 = tmp;

    b = 1 / (b - a);
    if (Math.abs(k1 * x - h1) <= k1 * tolerance) {
      return sgn * h1 + "/" + k1;
    }
    if (k1 >= max_den) {
      break;
    }
  }
  return x.toFixed(6);
}
function calculate() {
  let denklem = equation.value;
  denklem = replace(denklem, "-", "+-");
  denklem = replace(denklem, " ", "");
  denklem = denklem.split("+");
  for (let index = 0; index < denklem.length; index++) {
    const element = denklem[index];
    if (element.includes("x^")) {
      kök1_2.a =
        element.split("x")[0] == ""
          ? 1
          : element.split("x")[0] == "-"
          ? -1
          : Number(element.split("x")[0]);
    }
    if (!element.includes("^") && element.includes("x")) {
      kök1_2.b =
        replace(element, "x", "") == "-"
          ? -1
          : replace(element, "x", "") == ""
          ? 1
          : Number(replace(element, "x", ""));
    }
    if (!element.includes("^") && !element.includes("x")) {
      kök1_2.c = Number(element);
    }
  }
  kök1_2.delta = kök1_2.b ** 2 - 4 * kök1_2.a * kök1_2.c;
  kök2_2.a = kök1_2.a;
  kök2_2.b = kök1_2.b;
  kök2_2.c = kök1_2.c;
  kök2_2.delta = kök1_2.delta;
  kök1_1.value = (-kök1_2.b - Math.sqrt(kök1_2.delta)) / (2 * kök1_2.a);
  kök2_1.value = (-kök1_2.b + Math.sqrt(kök1_2.delta)) / (2 * kök1_2.a);
  if (!(kök1_1.value % 1 === 0)) {
    kök1_1.value = float2rat(kök1_1.value);
  }
  if (!(kök2_1.value % 1 === 0)) {
    kök2_1.value = float2rat(kök2_1.value);
  }
  if (isSquare(kök1_2.delta) && kök1_2.delta >= 0) {
    secondView.value = "none";
    firstView.value = "flex";
  } else {
    secondView.value = "flex";
    firstView.value = "none";
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(243, 243, 243);
  user-select: none;
}

.container {
  display: flex;
  flex-direction: column;
}
.input {
  padding: 1em;
}
.input:focus {
  outline: none;
}

.btn {
  padding: 1.1em;
  border-radius: 5px;
  border: none;
  margin: 0.5em;
}

.btn:hover {
  opacity: 0.8;
}

.btn.btn-submit {
  background-color: rgb(37, 171, 60);
  color: rgb(207, 255, 198);
  cursor: pointer;
}

.first-view {
  display: v-bind(firstView);
  flex-direction: column;
  align-items: flex-start;
  font-family: Arial, Helvetica, sans-serif;
}
.second-view {
  display: v-bind(secondView);
  flex-direction: column;
  align-items: flex-start;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1em;
}
.second-view .box {
  display: flex;
  flex-direction: row;
  margin: 0.4em;
}
.second-view .box .title {
  margin-right: 1em;
}
.first-view {
  display: v-bind(firstView);
  flex-direction: column;
  align-items: flex-start;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1em;
}
hr {
  height: 2px;
  border-width: 0;
  color: rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
}
.description {
  color: rgb(114, 114, 114);
  font-style: italic;
  margin-bottom: 10px;
}
</style>
